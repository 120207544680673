import React from "react";
import Link from 'components/Link'

import zparse from "helpers/zparse";

const Headline = ({ image, title, body, link, linkText, columnSize = 4, isPageHeader = false }) => {
	if (!title) return null;
	const isHtml = /<\/?[a-z][\s\S]*>/i;
	return (
		<div className={`headline ${isPageHeader ? `page-header` : ""}`} style={{ backgroundImage: `url('${image}')` }}>
			<div className="grid-container">
				<div className={`grid-x grid-padding-x ${isPageHeader ? "align-middle" : ""}`}>
					<div className={`cell small-12 small-order-1 medium-order-2 medium-${columnSize} content-block`}>
						{isPageHeader ? <h1>{zparse(title)}</h1> : <h2>{zparse(title)}</h2>}
						{body && (isHtml.test(body) ? zparse(body) : <p>{body}</p>)}
						{link && <Link to={link}>{linkText ? linkText : "Learn More"}</Link>}
					</div>
				</div>
			</div>
		</div>
	)
}

export default Headline;