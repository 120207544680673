import * as React from "react"
import { graphql } from 'gatsby';

import NotFound from 'pages/404';
import Layout from 'templates/Layout';
import Content from 'templates/Content';
import Select from 'templates/form/Select';

import Pager from 'global/Pager';

import DataProvider from 'providers/data';
import BlueCard from "components/BlueCard";

import article_thumbnail from  'images/news/article_thumbnail.jpg'
import news_thumbnail from  'images/news/news_thumbnail.jpg'
import article_news_thumbnail from "images/news/article_news_thumbnail.jpg";

const PostListing = (props) => {
	const { edges: pageEdges } = props.data.allContentPage;
	const { edges: nodes } = props.data.allBlogPost;
	const { edges: topicEdges } = props.data.allBlogTopic;
	let postsArr = nodes.map(({ node }) => node);
	const pageNodes = pageEdges.map(({ node }) => node);
	const topicNodes = topicEdges.map(({ node }) => node);
	let page = pageNodes[0];
	let { pageInfo } = props.data.allBlogPost;
	const { pageContext } = props;

	if ((!!props.providerResponse !== false && props.providerResponse.data.length > 0) && props.providerStatusText === 'LOADED' && pageContext.liveRefresh !== false) {
		postsArr = props.providerResponse.data;
		pageInfo = {
			hasPreviousPage: (props.providerResponse.page > 1),
			hasNextPage: (props.providerResponse.page < props.providerResponse.pages),
			perPage: props.providerResponse.pageSize,
			currentPage: props.providerResponse.page,
			totalCount: props.providerResponse.total,
			pageCount: props.providerResponse.pages,
		};
	}

	const pattern = ['/news/', '/news/page-$page/'];
	const [posts, setPosts] = React.useState(postsArr)
	const [topic, setTopic] = React.useState("")
	const [order, setOrder] = React.useState("desc")


	const topicOptions = [
		{
			label: "All Topics",
			value: ""
		}
	].concat(topicNodes.map(topic => ({ label: topic.name, value: topic.permalink })))

	const dateSortOptions = [
		{
			label: "Newest First",
			value: "desc"
		},
		{
			label: "Oldest First",
			value: "asc"
		},
	]

	React.useEffect(() => {
		if (props.providerStatusText === "LOADED") {
			setPosts(postsArr)
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.providerStatusText])

	const setFilter = ({ target }) => {
		setTopic(target.value);
	}

	const setSort = ({ target }) => {
		setOrder(target.value);
	}

	const filterPosts = () => {
		const sortOrder = function(a, b) {
			var key1 = new Date(a.showDate);
			var key2 = new Date(b.showDate);

			if (order === "asc") return key1 - key2;
			return key2 - key1;
		}

		let tmpPosts = postsArr.concat([]).sort(sortOrder)

		if (topic !== "") {
			tmpPosts = tmpPosts.filter(post => {
				let hasTopic = false;
				if (post.Topic) post.Topic.forEach(({ object }) => {
					 if (object.permalink === topic) hasTopic = true;
				});
				return hasTopic;
			}) 
		}

		setPosts(tmpPosts)
	}

	if (pageInfo.currentPage > pageInfo.pageCount && (props.providerStatusText === 'LOADED' || pageContext.liveRefresh === false)) {
		return <NotFound />
	}
	return (
		<Layout showContactForm={page.showContactForm} showNewsletterSignUp={page.showNewsletterSignUp} hoverboards={page.hoverboards}>
			<Content gridSize={12} {...page} />
			<section id="news">
				<div className="grid-container">
					
					<div className="grid-x grid-padding-x align-middle">
						<div className="cell small-12 medium-8 search-grid">
							<Select value={order} containerClassName="select-container" name="dateSort" label="Date Sort" placeholder="Date Sort" options={dateSortOptions} field={{ onChange: setSort }} />
							<Select value={topic} containerClassName="select-container" name="Topic" label="Topic" placeholder="Topic" options={topicOptions} field={{ onChange: setFilter }} />
							<button className={`button`} onClick={filterPosts} type="submit">Search</button>
						</div>
					</div>
					
					<div className="grid-x grid-margin-x small-up-1 medium-up-2 large-up-3">
						{posts.length > 0 && posts.map(post => {
							let topics = "";
							if (post.Topic.length) {
								topics = post.Topic.reduce((acc, topic, index) => {
									if (index === 0) {
										acc = topic.object.name
									} else {
										acc += " . " + topic.object.name
									}
									return acc;
								}, "")
							}
							
							let thumbnail = ""

							if (topics.includes("Article") && topics.includes("News")) {
								thumbnail = article_news_thumbnail
							} else if (topics.includes("Article") && !topics.includes("News")) {
								thumbnail = article_thumbnail
							} else if (!topics.includes("Article") && topics.includes("News")) {
								thumbnail = news_thumbnail
							}
							
							const card = {
								title: post.title,
								image: post.photo ? post.photo : thumbnail,
								body: post.intro,
								link: `/news/${post.permalink}/`,
								linkText: 'Read More',
								date: post.showDate,
								topics: topics
							}
							return (
								<div key={post.id} className={`cell`} style={{ marginBottom: '35px' }}><BlueCard colorScheme="inverted" {...card} /></div>
							)
						})}
						{posts.length === 0 && <div className="cell">No posts found, please try adjusting your search terms.</div>}
					</div>
				</div>
				<div className="grid-container">
					<div className="grid-x grid-margin-x">
						<div className="cell small-12">
							{(pageInfo.pageCount > 1) && <Pager page={pageInfo.currentPage} pages={pageInfo.pageCount} pattern={pattern} pageSize={pageInfo.perPage} total={pageInfo.totalCount} />}
						</div>
					</div>
				</div>
			</section>
		</Layout>
	)
}

// export default PostListing
const getData = (props) => <DataProvider liveRefresh={props.pageContext.liveRefresh} type="BlogPost" apiParams={{ _page: props.pageContext.page, _pageSize: props.pageContext.limit, _join: "Topic", _sort: "showDate", _order: "desc" }}><PostListing {...props} /></DataProvider>
export default getData;

export const query = graphql`
query ($skip: Int!, $limit: Int!) {
	allContentPage(
	  filter: {uri: {eq: "/news/"}, deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}
	) {
	  edges {
		node {
		  pageTitle
		  meta
		  content {
			main
		  }
		  showContactForm
		  showNewsletterSignUp
		  headlineBody
		  headlineTitle
		  headlineImage
		  headlineType
		  hoverboards
		}
	  }
	}
	allBlogPost(
	  filter: {approved: {eq: true}, archived: {eq: false}, deleted: {eq: false}}
	  sort: {order: [DESC, DESC], fields: [showDate, title]}
	  limit: $limit
	  skip: $skip
	) {
	  edges {
		node {
		  id
		  permalink
		  title
		  intro
		  photo
		  body
		  uri
		  showDate
		  Topic {
			object {
			  name
			  permalink
			}
		  }
		}
	  }
	  pageInfo {
		hasNextPage
		hasPreviousPage
		perPage
		currentPage
		totalCount
		pageCount
	  }
	}
	allBlogTopic {
	  edges {
		node {
		  name
		  permalink
		}
	  }
	}
  }   
`