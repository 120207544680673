import React from "react";

import zparse from "helpers/zparse";

const ColorCard = (props) => {
	const [dynHeight, setDynHeight] = React.useState("initial");

	React.useEffect(() => {
		const equalize = () => {
			let height = 0;
			const elements = document.querySelectorAll(
				".color-card .bottom-wrapper .content"
			);

			elements.forEach(({ clientHeight }) => {
				if (height < clientHeight) height = clientHeight;
			});

			setDynHeight(height);
		};
		window.addEventListener("resize", equalize);

		// on mount
		equalize();

		// on demount
		return () => window.removeEventListener("resize", equalize);
	}, []);

	let {
		title,
		hashtag,
		icon,
		points,
		useNumerical,
		hideBottom,
		content,
		centerTitle,
		hideIcon,
		titleAlign = 'left',
	} = props;

	if (!icon) hideIcon = true;

	if (hashtag && hashtag[0] !== "#") hashtag = "#" + hashtag;

	return (
		<div className={`color-card ${useNumerical ? "numbered" : ""} title-align-${titleAlign}`}>
			<div className={`top-wrapper ${centerTitle ? "align-center" : ""}`}>
				{!hideIcon && (
					<div className="icon">
						<img src={icon} alt="" />
					</div>
				)}
				<div className={`header`}>
					<h2>{zparse(title)}</h2>
					{/* <span>{hashtag}</span> */}
				</div>
			</div>
			{!hideBottom && (
				<div style={{ height: dynHeight }} className="bottom-wrapper">
					<div className="content">
						{!!points?.length && (
							<ul>
								{points.map(({ point }, index) => {
									return <li key={`point-${index}`}>{point}</li>;
								})}
							</ul>
						)}
						{!!content?.length && zparse(content)}
					</div>
				</div>
			)}
		</div>
	);
};

export default ColorCard;
