import React from "react";
import { Location } from "@reach/router";

import Headline from "global/Headline";
// import MapHeadline from "global/MapHeadline";
import { default as CommitmentCard } from "components/ColorCard";
import zparse from "helpers/zparse";
import DataProvider from "providers/data";

const Commitments = ({ commitments }) => (
	<section id="commitments">
		<div className="grid-container">
			<div className="grid-x grid-padding-x">
				<div className="cell flx">
					{!!commitments.length &&
						commitments.map((commitment, index) => {
							const { title, content, icon } = commitment;
							if (!title && !content) return null;
							return (
								<div key={`commitment-${index}`} className="color-card-holder">
									<CommitmentCard {...{ title, content, icon, index }} />
								</div>
							);
						})}
				</div>
			</div>
		</div>
	</section>
);

const ContentPage = (props) => {
	let page = { ...props };

	if (
		(props.providerResponse.data.length > 0 || !!page !== false) &&
		props.providerStatusText === "LOADED"
	) {
		page = { ...page, ...props.providerResponse.data[0] }; // merge custom template props into latest data
		page = { ...page, ...props.forceOptions };
	}

	const commitments = [
		{
			title: page.commitment1Title,
			content: page.commitment1Content,
			icon: page.commitment1Icon,
		},
		{
			title: page.commitment2Title,
			content: page.commitment2Content,
			icon: page.commitment2Icon,
		},
		{
			title: page.commitment3Title,
			content: page.commitment3Content,
			icon: page.commitment3Icon,
		},
	];

	return (
		<>
			{/* {props.useMap && <MapHeadline {...{ body: page.headlineBody, title: page.headlineTitle, image: page.headlineImage, columnSize: 5, isPageHeader: true, link: page.link, linkText: page.linkText, type: page.headlineType }} />} */}
			{!props.hideHeadline && (
				<Headline
					{...{
						body: page.headlineBody,
						title: page.headlineTitle,
						image: page.headlineImage,
						columnSize: 5,
						isPageHeader: true,
						link: page.link,
						linkText: page.linkText,
						type: page.headlineType,
					}}
				/>
			)}
			{props.moveCommitmentsAboveContent && (
				<Commitments commitments={commitments} />
			)}
			{!props.hideContent && (
				<section
					id="content-page"
					className={`content-page-${props.location.pathname.replace(
						/\//gm,
						""
					)}`}
				>
					<div className="grid-container">
						<div className="grid-margin-x grid-x align-center">
							<div
								className={`small-12 medium-${
									props.gridSize ? props.gridSize : 8
								} cell`}
							>
								{zparse(page.content ? page.content.main : "")}
							</div>
						</div>
					</div>
					{props.children}
				</section>
			)}
			{!props.moveCommitmentsAboveContent && (
				<Commitments commitments={commitments} />
			)}
		</>
	);
};

const Content = (props) => (
	<Location>
		{(locationProps) => (
			<DataProvider
				liveRefresh={true}
				type="ContentPage"
				apiParams={{ uri: locationProps.location.pathname }}
			>
				<ContentPage {...props} location={locationProps.location} />
			</DataProvider>
		)}
	</Location>
);
export default Content;
