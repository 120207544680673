import React from "react"
import Link from 'components/Link'

import cooling from "images/projects/cooling.svg";
import heating from "images/projects/heating.svg";
import electricity from "images/projects/electricity.svg";

const typeMap = {
	cooling,
	heating,
	electricity,
}

const BlueCard = props => {
	let showMeta = false
	if (props.date || props.topics) {
		showMeta = true
	}

	const date = new Date(props.date).toLocaleDateString()

	return (
		<div className={`blue-card ${props.colorScheme}`}>
			<Link to={props.link}>
				{ props.image && <img src={props.image} alt={props.title} /> }
				<h3>{props.title}</h3>
				{showMeta && <strong>{`${props.topics ? `${date} · ` : date}`}{props.topics}</strong>}
				{ (props.types && !!props.types.length) && props.types.map(type => <img key={type} src={typeMap[type]} alt={type} />
				) }
				{ !props.shrink &&
					<>
						<p>{props.body}</p>
						<div>{props.linkText}</div>
					</>
				}
			</Link>
		</div>
	)
}

export default BlueCard;